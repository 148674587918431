<template>
  <BCard>
    <div class="text-black text-2xl font-medium mb-2">
      Data Partner
    </div>
    <div class="flex gap-[7px] justify-end">
      <div
        class="flex items-center max-w-[250px] rounded-[8px] gap-[5px] px-[8px]  border-[1px] border-[#E2E2E2]"
      >
        <span
          class="k-search-normal-1 font-bold  align-middle"
          style="font-size: 20px"
        />
        <BFormInput
          v-model="search"
          placeholder="Cari nama, email"
          class="border-0"
          @input="searchData"
        />
      </div>
      <BDropdown
        id="dropdown-1"
        no-caret
        toggle-class="toggle-custom"
        variant="primary"
        right
      >
        <template #button-content>
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
            alt="filter"
          >
        </template>
        <div
          class="text-[#333333] my-1 font-semibold text-[14px] px-[10px] min-w-[250px]"
        >
          Filter
        </div>
        <hr>
        <div class="x-[10px] my-1">
          <BDropdown
            id="dropdown-2"
            variant="none"
            toggle-class="toggle-form"
            class="w-full text-left mr-[100px]"
            no-caret
            dropleft
            left
          >
            <template #button-content>
              <div class="text-left -ml-[10px] font-[400] text-[#333333]">
                Kartu Komcards
              </div>
            </template>
            <b-form-group>
              <b-form-radio-group
                id="btn-radios-1"
                v-model="state_radio"
                name="radios-stacked"
                stacked

                class="px-[6px] pt-[3px] w-full min-w-[165px]"
              >
                <b-form-radio
                  name="Punya Kartu"
                  value="true"
                  class="text-[#626262] font-[400] text-[14px]"
                >
                  Punya Kartu
                </b-form-radio>
                <b-form-radio
                  class="text-[#626262] font-[400] mt-1 text-[14px]"
                  name="Tidak Punya"
                  value="false"
                >
                  Tidak Punya
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </BDropdown>
        </div>
        <hr>
        <div class="px-[10px] my-1 grid grid-cols-2 gap-[4px]">
          <BButton
            variant="outline-primary"
            @click="handleReset"
          >
            Reset
          </BButton>
          <BButton
            variant="primary"
            @click="handleSubmit"
          >
            Terapkan
          </BButton>
        </div>
      </BDropdown>
    </div>

    <BOverlay
      :show="loadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        class="mt-1"
        style="height: calc(100vh - 250px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data"
          responsive
          hover
          selectable
          select-mode="single"
        >
          <template #cell(partner_name)="data">
            <span class="text-[14px] font-semibold"> {{ data.item.name }}</span>
            <br>
            <span class="text-[#828282] text-[14px]">
              {{ data.item.email }}</span>
          </template>
          <template #cell(telephone)="data">
            <span
              class="text-[14px] text-[#333333] text-left  flex justify-start w-100"
            >
              {{ data.item.phone }}</span>
          </template>

          <template #cell(used_card_count)="data">
            <div
              class="text-[14px] text-[#333333] text-left  flex justify-start w-100"
            >
              <span class="text-[#34A770]">{{ data.item.used_card_count }}</span>/<span class="text-[#828282]">{{
                data.item.quota_card_count
              }}</span>
            </div>
          </template>
          <template #cell(total_spending)="data">
            <span
              class="text-[14px] text-[#333333] text-left flex justify-start w-100"
            >
              {{ IDR(data.item.total_spending,2,2) }}</span>
          </template>
          <template #cell(last_transaction)="data">
            <span
              class="text-[14px] text-[#333333] text-left  flex justify-start w-100"
            >
              {{
                data.item.last_transaction
                  ? DAY_MONTH_YEAR(data.item.last_transaction)
                  : '-'
              }}</span>
          </template>
          <template #cell(more)="data">
            <span class="flex justify-center">
              <BDropdown
                variant="none"
                no-caret
                offset="-100"
                left
                size="sm"
              >
                <template #button-content>
                  <button class="bg-transparent">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
                      alt="more-icon"
                    >
                  </button>
                </template>
                <div
                  class="flex gap-[3px] items-center px-[4px] hover:bg-[#f8f8f8] text-[#626262] text-[14px]"
                  @click="$router.push('/komcards-data-partner/' + data.item.user_id)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg"
                    alt="eye"
                  >
                  <div>Detail Partner</div>
                </div>
              </BDropdown>
            </span>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'
import { columnConfig } from './config'

export default {
  data() {
    return {
      items: null,
      loadingTable: false,
      fields: columnConfig,
      IDR,
      DAY_MONTH_YEAR,
      lastData: false,
      offset: 0,
      limit: 25,
      search: null,
      has_card: true,
      state_radio: true,
    }
  },
  mounted() {
    this.getList()

    // to handle nested dropdown click anomaly
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
        this.state_radio = this.has_card
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    handleReset() {
      this.offset = 0
      this.has_card = true
      this.getList()
    },
    handleSubmit() {
      this.offset = 0
      this.has_card = this.state_radio
      this.getList()
    },
    searchData: _.debounce(function search(text) {
      this.offset = 0
      this.getList()
    }, 1000),
    async getList() {
      this.loadingTable = true

      const params = {
        search: this.search,
        has_card: this.has_card,
        offset: this.offset,
        limit: this.limit,
      }

      const url = '/auth/api/v1/komcard/partners'

      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.loadingTable = false
          this.offset = data.length
          this.items = data
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal load data' })
          this.loadingTable = false
        })
    },
    getNextData() {
      if (!this.lastData) {
        this.loadingTable = true
        const params = {
          search: this.search,
          has_card: this.has_card,
          offset: this.offset,
          limit: this.limit,
        }

        const url = '/auth/api/v1/komcard/partners'

        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loadingTable = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loadingTable = false
            this.$toast_error({ message: 'Gagal load data' })
          })
      }
    },
  },
}
</script>

<style scoped>
.dropdown-toggle:hover {
  background-color: white !important;
}
</style>
